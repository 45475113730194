<template>
  <div class="mains-wrapper">
    <div class="mains" v-show="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div class="container_warp_add">
      <!-- 顶部工具栏 -->
      <top-tools class="top-operating" :selectCell="selectCell" :graph="graph" :connectEdgeTypes="connectEdgeType" :routerName="'iotConfigManageBaseAdd'" @onLoading="onLoading"></top-tools>
      <!-- 左侧图形库 -->
      <left-drawer class="left_drawer" :graph="graph" :resourceData="resourceData"></left-drawer>
      <div class="warpper-chart">
        <div id="containerChart" ref="containerChart"></div>
      </div>
      <!-- 右侧配置栏 -->
      <right-drawer class="right_drawer" :drawerType="type" :selectCell="selectCell" :graph="graph" :grid="grid" :resourceData="resourceData"></right-drawer>
    </div>
  </div>
</template>
<script>
import '@antv/x6-vue-shape'
import { Graph,Shape,FunctionExt,DataUri} from '@antv/x6';
import TopTools from './configComponent/TopTools';
import LeftDrawer from './configComponent/LeftDrawer';
import RightDrawer from './configComponent/RightDrawer';
import insertCss from 'insert-css';
import { importConfig } from '@/js/Graph/importConfig';

const data = {};
export default {
    components:{
      TopTools,
      LeftDrawer,
      RightDrawer
    },
    data() {
      return {
        loading: false,
        graph:'',
        value1: true,
        type:'grid',
        selectCell:'',
        connectEdgeType:{  //连线方式
          connector: 'normal',
          router: {
            name: ''
          }
        },
        grid:{ // 网格设置
          size: 1,      // 网格大小 1px
          visible: true, // 渲染网格背景
          type: 'mesh',
          args: {
            color: '#D0D0D0',
            thickness: 1,     // 网格线宽度/网格点大小
            factor: 10, 
          },
        },
        resourceData: [],
      }
    },
    methods: {
      // 初始化画布
      initX6(){
        var _that = this
        this.graph = new Graph({
          container: document.getElementById('containerChart'),
          width: '100%',
          grid: _that.grid,//画布网格
          panning: {//平移设置
            enabled: false,//开启平移
            modifiers: 'ctrl',//如何其他操作有冲突,可用修饰参数触发
            eventTypes: ['leftMouseDown','rightMouseDown','mouseWheel'],//设置触发画布的拖拽行为,默认为['leftMouseDown']          
          },
          scroller: {
            enabled: true,
            pannable: true,
            pageVisible: false,
            pageBreak: false,
          },
          mousewheel: {//滚轮缩放
            enabled: true,
            modifiers: ['ctrl','meta'],
          },
          resizing: { //调整节点宽高
            enabled: true,
            orthogonal:false,
          }, 
          rotating: {//调整旋转角度
            enabled: true,
            grid: 5,
          },
          translating: {//限制节点移动范围(不能超出画布)
            restrict: false,
          },
          snapline: {//开启对齐线
            enabled: true,
            tolerance: 1,
          },
          interacting: {//定制节点和边的交互行为
            edgeLabelMovable: true
          },
          selecting: {//点选框选
            enabled: true,
            multiple: true,
            rubberband: true,
            strict: true,
            modifiers: 'ctrl',
          },
          clipboard: {//复制粘贴
            enabled: true,
            useLocalStorage: true,
          },
          keyboard: {//键盘复制粘贴
            enabled: true,
          },
          history: {//撤销重做
            enabled: true,
          },
          connecting: { // 节点连线
            anchor: 'center',
            connectionPoint: 'anchor',
            allowBlank: false,
            snap: true,
            createEdge () {
              return new Shape.Edge({
                attrs: {
                  line: {
                    stroke: '#1890ff',
                    strokeWidth: 1,
                    targetMarker: {
                      name: 'classic',
                      size: 8
                    },
                    strokeDasharray: 0, //虚线
                    style: {
                      animation: 'ant-line 30s infinite linear',
                    },
                  },
                },
                label: {
                  text:''
                },
                connector: _that.connectEdgeType.connector,
                router: {
                  name: _that.connectEdgeType.router.name || ''
                },
                zIndex: 0
              })
            },
          },
          highlighting: {//高亮选项
            magnetAvailable: {
              name: 'stroke',
              args: {
                padding: 4,
                attrs: {
                  strokeWidth: 4,
                  stroke: '#6a6c8a'
                }
              }
            }
          },
        });
        insertCss(`
          @keyframes ant-line {
            to {
                stroke-dashoffset: -1000
            }
          }
        `)
        this.graph.fromJSON(data)
        this.graph.history.redo()
        this.graph.history.undo()
        this.graph.centerContent()
        // 监听多选拖拽
        this.graph.on('node:move',()=>{
          if(this.graph.getSelectedCells() && this.graph.getSelectedCells().length>1) {
            this.graph.disableSnapline();
          }else {
            this.graph.enableSnapline();
          }
        })
        // 绑定撤销事件
        this.graph.bindKey('ctrl+z',()=>{
          this.graph.history.undo()
        })
        // 绑定重做事件
        this.graph.bindKey('ctrl+y',()=>{
          this.graph.history.redo()
        })
        // 绑定键盘事件
        this.graph.bindKey('ctrl+c',()=>{
          //获取被选中元素
          const cells=this.graph.getSelectedCells()
          if(cells&&cells.length){
              this.graph.copy(cells,{
                  deep:true,
                  //深度遍历节点
                  useLocalStorage:true,
              })
              console.log('已经复制了节点')
          }else{
              console.log('必须选中节点才能复制')
          }
        })
        this.graph.bindKey('ctrl+v',()=>{
          if(this.graph.isClipboardEmpty()){
              console.log('没有选中的元素,不能粘贴')
          }else{
              const cell=this.graph.paste({
                  useLocalStorage:true,
                  offset:20,
                  //复制出来的节点和原来节点的位置
              })
              this.graph.cleanSelection()
              //清空之前选择的元素
              this.graph.select(cell)
              //选中新复制出来的元素
          }
        })
        this.graph.bindKey('delete',()=>{
          const cells=this.graph.getSelectedCells()
          console.log(cells,cells&&cells.length,cells.length)
          if(cells&&cells.length){
              this.graph.cut(cells,{
                  useLocalStorage:true,
                  //是否删掉本地缓存
                  deep:true,
                  //是否深度遍历删掉全部子节点
              })
          }else{
              console.log('没有选中的元素,不能删除')
          }
        })
        // 鼠标移入节点
        this.graph.on('node:mouseenter',FunctionExt.debounce(() => {
            const container =  document.getElementById('containerChart')
            const ports = container.querySelectorAll(
              '.x6-port-body'
            )
            this.showPorts(ports, true)
          }),
          500
        )
        // 鼠标移出节点
        this.graph.on('node:mouseleave', () => {
          const container =  document.getElementById('containerChart')
          const ports = container.querySelectorAll(
            '.x6-port-body'
          )
          this.showPorts(ports, false)
        })
        // 监听画布点击事件
        this.graph.on('blank:click', () => {
          this.type = 'grid'
        })
        // 监听基类(node/edge)点击事件
        this.graph.on('cell:click', ({ cell }) => {
          this.type = cell.isNode() ? 'node' : 'edge'
          cell.attr('valveSvg/class', 'valve-rotata')
          // cell.attr('levelRect2/class', 'level-translate')
        })
        // 监听节点调整大小后
        this.graph.on('node:resized',({node})=>{
          if(node.attrs.waterline1) {
            node.attr('svgele/width', node.size().width)
            node.attr('svgele/height', node.size().height)
            
            node.attr({
              waterline1: {
                style: {
                  'transform': `translate(0px, ${(node.size().height)/2}px)`
                }
              },
              waterline2: {
                style: {
                  'transform': `translate(${-(node.size().width)-10}px, ${(node.size().height)/2}px)`
                }
              }
            })
            node.attr('waterline1/strokeWidth', 4)
            node.attr('waterline2/strokeWidth', 4)
          }
          if(node.attrs.levelRect) {
            node.attr('levelSvg/width', node.size().width)
            node.attr('levelSvg/height', node.size().height)
            node.attr('levelRect/width', node.size().width)
            node.attr('levelRect/height', node.size().height)
            node.attr('levelRect2/width', node.size().width)
            node.attr('levelRect2/height', node.size().height)
            node.attr({
              levelRect2: {
                style: {
                  'transform': `translateY(${(node.size().height)-10}px)`
                }
              }
            })
          }
        })
        // 监听自定义事件
        this.graph.on('node:rectclick', ({ node }) => {
          console.log(node.store.data.data.status)
          if(node.store.data.data.status==0) {
            this.selectCell.attr('tipOpenBtn/text', '开启')
            node.store.data.data.status = 1
          }else if(node.store.data.data.status==1) {
            this.selectCell.attr('tipOpenBtn/text', '关闭')
            node.store.data.data.status = 0
          }
        })
        // 监听节点鼠标按下事件
        this.graph.on('node:mousedown', ({ node }) => {
          if(node.store.data.status=='button') {
            // console.log(node.store.data.status)
            // console.log(1)
          }
        })
        // 监听节点鼠标松开事件
        this.graph.on('node:mouseup', ({ node }) => {
          if(node.store.data.status=='button') {
            // console.log(node.store.data.status)
            // console.log(0)
          }
        })
        this.graph.on('selection:changed', (args) => {
          args.added.forEach(cell => {
            this.selectCell = cell
            if(cell.isEdge()){
              cell.isEdge() && cell.attr('line/strokeDasharray', 5) //虚线蚂蚁线
              cell.addTools([
                {
                  name: 'vertices',
                  args: {
                    padding: 4,
                    attrs: {
                      strokeWidth: 0.1,
                      stroke: '#2d8cf0',
                      fill: '#ffffff',
                    }
                  },
                },
              ])
            }
          })
          args.removed.forEach(cell => {
            cell.isEdge() && cell.attr('line/strokeDasharray', 0)  //正常线
            cell.removeTools()
          })
        })
      },
      // 显示节点连接桩
      showPorts(ports, show) {
        for (let i = 0, len = ports.length; i < len; i = i + 1) {
          ports[i].style.visibility = show ? 'hidden' : 'hidden'
        }
      },
      // 回显组态
      exhibitionConfig() {
        importConfig(this.graph)
      },
      loadResourceData() {
        this.$ajax.post('resourceQuery').then(res => {
          if(res.code==0) {
            this.resourceData = res.data
          }
        })
      },
      onLoading(loading) {
        this.loading = loading
      }
    },
    mounted(){
      this.initX6()
      this.exhibitionConfig()
      this.loadResourceData()
    }
};
</script>
<style lang="less" scoped>
.mains-wrapper {
  position: relative;
  height:97.4%;
  padding:0;
  background: #E9EEEF;
}
.mains {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
/deep/.x6-widget-transform {
  border: 1px dashed #fff;
}
/deep/.x6-graph-scroller {
  height: 100% !important;
}
</style>
<style lang="less">
  @import '../../css/iotConfigManage/configAdd.less';
</style>